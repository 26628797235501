import React, { useState } from "react";
import "../styles/styles.css";
import Popup from "./Popup";
import "../styles/FormBuilder.css";
import Section from "./form/Section";

interface FormBuilderProps {
    user: { token: string };
    setShowFormBuilder: (value: boolean) => void;
    formName: string;
    formDescription: string;
    editorName: string;
    editorNotes: string;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ user, setShowFormBuilder, formName, formDescription, editorName, editorNotes }) => {
    const [formSchema, setFormSchema] = useState<any[]>([]);
    const [showSavePage, setShowSavePage] = useState(false);
    const [formType, setFormType] = useState<string | null>(null);
    const [showWarning, setShowWarning] = useState(false);

    const addFormElement = () => {
        const newSection = {
            id: formSchema.length + 1,
            title: '',
            queries: [],
        };
        setFormSchema([...formSchema, newSection]);
    };

    const onChange = (index: number, data: any) => {
        const updatedFormSchema = [...formSchema];
        updatedFormSchema[index] = { ...updatedFormSchema[index], ...data };
        setFormSchema(updatedFormSchema);
    };

    const saveFormChanges = async (formType: string) => {
        setFormType(formType);
        
        const formConfig = {
            name: formName,
            description: formDescription,
            editor_name: editorName,
            editor_notes: editorNotes,
            version: "1.0",
            last_edited: new Date().toISOString(),
            type: formType,
            sections: formSchema,
        };

        console.log("Form Saved");
        console.log("Form Config:", JSON.stringify(formConfig, null, 2));

        try {
            const response = await fetch(`/api/create_new_form`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formConfig, null, 2),
            });

            const data = await response.json();

            if (data.success) {
                console.log("Successfully created form");
            }
        } catch (error) {
            console.error("Error creating form:", error);
        }

        setShowSavePage(false);
    };

    const saveForm = () => {
        console.log("Entering saveForm");
        setShowSavePage(true);
    };

    const goBack = () => {
        if (formSchema.length > 0) {
            setShowWarning(true);
        } else {
            setShowFormBuilder(false);
        }
    };

    return (
        <div>
            <h2>Form Builder</h2>
            <h2>Shifts</h2>

            <button onClick={addFormElement}>Add Section</button>

            <div className="form-builder">
                {formSchema.map((item, index) => (
                    <Section
                        key={index}
                        onChange={(field, data) => onChange(index, { ...item, [field]: data })}
                    />
                ))}
            </div>
            
            <button className="back-btn" onClick={goBack}>
                Go Back
            </button>
            <button className="save-btn" onClick={saveForm}>
                Save Form
            </button>
            <Popup trigger={showSavePage} setTrigger={setShowSavePage}>
                <h2>What Type of Form?</h2>
                <button onClick={() => saveFormChanges("match")}>Match</button>
                <button onClick={() => saveFormChanges("pit")}>Pit</button>
            </Popup>

            <Popup trigger={showWarning} setTrigger={setShowWarning}>
                <h2>You have unsaved changes</h2>
                <p>Do still want to go back?</p>
                <button onClick={() => setShowWarning(false)}>Cancel</button>
                <button onClick={() => setShowFormBuilder(false)}>Go Back</button>
            </Popup>
        </div>
    );
};

export default FormBuilder;
