import React, { useState, useEffect, useRef } from "react";

interface CounterProps {
    min?: number;
    max?: number;
    onChange: (data: { title: string; min: number | undefined; max: number | undefined }) => void;
}

const Counter: React.FC<CounterProps> = ({ min, max, onChange }) => {
    const [title, setTitle] = useState<string>("");

    // Use refs to track previous values
    const prevTitleRef = useRef(title);
    const prevMinRef = useRef(min);
    const prevMaxRef = useRef(max);

    useEffect(() => {
        // Only call onChange if the values actually changed
        if (prevTitleRef.current !== title || prevMinRef.current !== min || prevMaxRef.current !== max) {
            onChange({ title, min, max });
            prevTitleRef.current = title;
            prevMinRef.current = min;
            prevMaxRef.current = max;
        }
    }, [title, min, max, onChange]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    return (
        <div>
            <h1>Counter</h1>
            <input
                type="text"
                value={title}
                placeholder="Title"
                onChange={handleInputChange}
            />
            <div>
                <label>Min:</label>
                <input
                    type="number"
                    value={min ?? ""}
                    onChange={(e) => onChange({ title, min: parseInt(e.target.value, 10), max })}
                />
            </div>
            <div>
                <label>Max:</label>
                <input
                    type="number"
                    value={max ?? ""}
                    onChange={(e) => onChange({ title, min, max: parseInt(e.target.value, 10) })}
                />
            </div>
        </div>
    );
};

export default Counter;
