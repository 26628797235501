import React, { useState } from "react";
import Popup from "../components/Popup.tsx";
import "../styles/Login.css";
import supabase from "../auth.ts";
import steelhawk from "../assets/steelhawks.jpg";

const appVersion = "WEB-1.0.0";

const Login = ({ setUser }) => {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [email, setEmail] = useState("");
    const [osis, setOsis] = useState("");

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");

    const [showAccountCreator, setShowAccountCreator] = useState(false);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleOsisChange = (event) => {
        setOsis(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: username + "@nycstudents.net",
                password: password,
            });

            const user = {
                id: data.sub,
                name: data.name,
                username: data.username,
                osis: data.osis,
                email: data.email,
                role: data.role,
            };
            setUser(user);

            if (error) {
                setPopupMessage("Invalid username or password");
                setShowPopup(true);
                console.log("Login Failed");
                return;
            }
        } catch (error) {
            console.error("Error logging in:", error);
        }
    };

    const handleAccountCreation = async (event) => {
        event.preventDefault();

        fetch("/api/create_account", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                osis: password,
                name: name,
            }),
        })
            .then((response) => {
                if (response.status === 401) {
                    setPopupMessage(
                        "Unauthorized: Please use your DOE email and try again."
                    );
                    setShowPopup(true);
                    return;
                }

                return response.json();
            })
            .then((data) => {
                console.log(data);

                if (data.message) {
                    setPopupMessage("Account created successfully");
                    setShowPopup(true);
                }
            })
            .catch((error) => {
                console.error("Error creating user:", error);
                setPopupMessage("Error creating account");
                setShowPopup(true);
            });
    };

    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
            }}
        >
            <div className="login-container">
                <div className="form-login-container">
                    <h1>Welcome Back</h1>
                    <p>Sign in to your account</p>
                    <div className="divider"></div>

                    <form method="post" className="" onSubmit={handleLogin}>
                        <label htmlFor="username" className="label">
                            Username:
                        </label>
                        <input
                            type="text"
                            id="username"
                            className="login-input"
                            name="username"
                            value={username}
                            onChange={handleUsernameChange}
                            required
                        />
                        <label htmlFor="password" className="label">
                            Password:
                        </label>
                        <input
                            type="password"
                            id="password"
                            className="login-input"
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <button type="submit">Login</button>
                    </form>
                    <h3>or</h3>
                    <button
                        style={{ width: "300px" }}
                        onClick={() => {
                            document.querySelector(
                                ".form-login-container"
                            ).style.transform = "translateX(-1000px)";
                            document.querySelector(
                                ".form-createAccount-container"
                            ).style.transform = "translateX(0)";
                        }}
                    >
                        Create Account
                    </button>
                    <p>Version: {appVersion}</p>
                    <p style={{ marginTop: 0 }}>
                        © 2024 Steel Hawks Robotics Inc.
                    </p>
                </div>

                <div className="form-createAccount-container">
                    <h1>Welcome</h1>
                    <p>Make an Account</p>
                    <div className="divider"></div>

                    <form
                        method="post"
                        className=""
                        onSubmit={handleAccountCreation}
                    >
                        <label htmlFor="name" className="label">
                            Full Name:
                        </label>
                        <input
                            type="text"
                            id="name"
                            className="login-input"
                            name="name"
                            value={name}
                            onChange={handleNameChange}
                            required
                        />

                        <label htmlFor="email" className="label">
                            DOE Email:
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="login-input"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                        />

                        <label htmlFor="osis" className="label">
                            OSIS:
                        </label>
                        <input
                            type="osis"
                            id="osis"
                            className="login-input"
                            name="osis"
                            value={osis}
                            onChange={handleOsisChange}
                            required
                        />
                        <button type="submit">Create Account</button>
                    </form>
                    <h3>or</h3>
                    <button
                        style={{ width: "300px" }}
                        onClick={() => {
                            document.querySelector(
                                ".form-createAccount-container"
                            ).style.transform = "translateX(1000px)";
                            document.querySelector(
                                ".form-login-container"
                            ).style.transform = "translateX(0)";
                        }}
                    >
                        Login
                    </button>
                    <p>Version: {appVersion}</p>
                    <p style={{ marginTop: 0 }}>
                        © 2024 Steel Hawks Robotics Inc.
                    </p>
                </div>

                <Popup trigger={showPopup} setTrigger={setShowPopup}>
                    <h1>{popupMessage}</h1>
                </Popup>
            </div>
            <div className="image-container">
                <img
                    src={steelhawk}
                    style={{ borderRadius: 20, alignSelf: "center" }}
                />
            </div>
        </div>
    );
};

export default Login;
