import React, { useState, useEffect, useRef } from "react";

interface Option {
    id: number;
    name: string;
    value: string;
}

interface RadioGroupMultipleProps {
    title?: string;
    options?: Option[];
    onChange: (data: { title: string; options: Option[] }) => void;
}

const RadioGroupMultiple: React.FC<RadioGroupMultipleProps> = ({ title = "", options = [], onChange }) => {
    const [localTitle, setLocalTitle] = useState<string>(title);
    const [localOptions, setLocalOptions] = useState<Option[]>(options);

    const prevTitleRef = useRef(localTitle);
    const prevOptionsRef = useRef(localOptions);

    useEffect(() => {
        if (prevTitleRef.current !== localTitle || prevOptionsRef.current !== localOptions) {
            onChange({ title: localTitle, options: localOptions });
            prevTitleRef.current = localTitle;
            prevOptionsRef.current = localOptions;
        }
    }, [localTitle, localOptions, onChange]);

    const handleAddOption = () => {
        const newOption: Option = {
            id: localOptions.length + 1,
            name: `Option ${localOptions.length + 1}`,
            value: toCamelCase(`Option ${localOptions.length + 1}`), // Ensure value is set
        };
        setLocalOptions([...localOptions, newOption]);
    };

    const toCamelCase = (str: string): string => {
        return str
            .toLowerCase()
            .replace(/(?:^\w|[A-Z]|\b\w|\s+|_|-)/g, (match, index) =>
                index === 0 ? match.toLowerCase() : match.toUpperCase()
            )
            .replace(/\s+|_|-/g, '');
    };

    const handleRemoveOption = (id: number) => {
        const updatedOptions = localOptions.filter((option) => option.id !== id);
        setLocalOptions(updatedOptions);
    };

    const handleOptionNameChange = (id: number, newName: string) => {
        const updatedOptions = localOptions.map((option) =>
            option.id === id ? { ...option, name: newName, value: toCamelCase(newName) } : option
        );
        setLocalOptions(updatedOptions);
    };

    const styles = {
        container: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            width: "300px",
            margin: "0 auto",
        },
        optionContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
        },
        radioButton: {
            marginRight: "10px",
        },
        button: {
            padding: "5px 10px",
            margin: "5px",
            border: "none",
            borderRadius: "4px",
            backgroundColor: "#007BFF",
            color: "white",
            cursor: "pointer",
        },
        removeButton: {
            padding: "5px 10px",
            marginLeft: "10px",
            border: "none",
            borderRadius: "4px",
            backgroundColor: "#DC3545",
            color: "white",
            cursor: "pointer",
        },
    };

    return (
        <div style={styles.container}>
            <h1>Radio Group Multiple</h1>
            <input
                type="text"
                id="title"
                value={localTitle}
                onChange={(e) => setLocalTitle(e.target.value)}
            />
            {localOptions.map((option) => (
                <div key={option.id} style={styles.optionContainer}>
                    <input
                        type="text"
                        value={option.name}
                        onChange={(e) => handleOptionNameChange(option.id, e.target.value)}
                    />
                    <button style={styles.removeButton} onClick={() => handleRemoveOption(option.id)}>-</button>
                </div>
            ))}
            <button style={styles.button} onClick={handleAddOption}>+</button>
        </div>
    );
};

export default RadioGroupMultiple;
