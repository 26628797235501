import React from "react";
import "../styles/Popup.css";

interface PopupProps {
    trigger: boolean;
    setTrigger: (value: boolean) => void;
    onClose?: () => void;
    children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = (props: PopupProps) => {
    return props.trigger ? (
        <div className="popup">
            <div className="popup-inner">
                <button
                    unselectable="on"
                    className="close-btn"
                    onClick={() => {
                        props.setTrigger(false);
                        props.onClose && props.onClose();
                    }}
                >
                    ×
                </button>
                {props.children}
            </div>
        </div>
    ) : null;
}

export default Popup;
