import React from "react";
import { Link } from "react-router-dom";

const Unauthenticated = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div>
                <h1>You are not logged in</h1>
                <Link to="/">Login Page</Link>
            </div>
        </div>
    );
};

export default Unauthenticated;
