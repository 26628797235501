import Entry from "../components/interface/Entry";
import Popup from "../components/Popup";
import DataNavbar from "../components/DataNavbar";
import React, { useEffect, useState } from "react";
import "../styles/DataView.css";
import Card from "../components/Card";
import { FaPlaneArrival } from "react-icons/fa";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

const DataView = () => {
    const views = Object.freeze({
        EVENT_VIEWER: "Event Viewer",
        SELECTOR: "Selector",
        MATCH_SCOUTING_INTERFACE: "MATCH_SCOUTING_INTERFACE",
        COMPARISON: "COMPARISON",
    });

    useEffect(() => {
        fetch("/api/all_competitions")
            .then((response) => response.json())
            .then((data) => {
                setEvents(
                    data.all_competitions.map(
                        (competition: any) => competition.name
                    )
                );
            })
            .catch((error) => console.error(error));
    }, []);

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [events, setEvents] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [selectedEvent, setSelectedEvent] = useState<string>("");

    const handleEventViewer = (evt: string) => {
        setSelectedEvent(evt);
        setSelectedView(views.SELECTOR);

        fetch(`/api/get_comp_data/${evt}`, {
            method: "POST",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setData(data);
                // setSelectedView(views.MATCH_SCOUTING);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {}, []);
    const handlePitScoutingView = () => {};
    const handleMatchScoutingView = () => {};
    const handleMatchScoutingInterfacesView = () => {};
    const handleComparisonView = () => {};

    const [selectedView, setSelectedView] = useState<string>(
        views.EVENT_VIEWER
    );

    const goBack = () => {
        setSelectedView(views.EVENT_VIEWER);
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h1>Data</h1>
            </div>
            {selectedView === views.EVENT_VIEWER && (
                <div className="events">
                    {events.map((event: any) => (
                        <Card
                            key={event}
                            title={event}
                            icon={<FaPlaneArrival />}
                            description="View Event"
                            onClick={() => handleEventViewer(event)}
                        />
                    ))}
                </div>
            )}

            {selectedView === views.COMPARISON && (
                <div>
                    <h1>Comparison</h1>

                </div>
            )}

            {selectedView === views.SELECTOR && (
                <div>
                    <h1>Current Event: {selectedEvent}</h1>
                    <DataNavbar
                        views={views}
                        setSelectedView={setSelectedView}
                    />
                    <button className="back-btn" onClick={goBack}>
                        Go Back
                    </button>
                </div>
            )}

            {selectedView !== views.EVENT_VIEWER &&
                selectedView !== views.SELECTOR && (
                    <button
                        className="back-btn"
                        onClick={() => setSelectedView(views.SELECTOR)}
                    >
                        Go Back
                    </button>
                )}

            <Popup trigger={showPopup} setTrigger={setShowPopup}>
                <h3>Popup</h3>
                <p>This is a popup</p>
            </Popup>
        </div>
    );
};

export default DataView;
