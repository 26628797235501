import { GoHomeFill } from "react-icons/go";
import { RiAdminLine } from "react-icons/ri";
import { FaWpforms } from "react-icons/fa6";
import { BsDatabaseDown } from "react-icons/bs";
import { MdOutlineSchedule } from "react-icons/md";
import React from 'react';

export const SidebarDataAdmin = [
    {
        title: 'Home',
        redirect: '/',
        icon: <GoHomeFill />,
    },
    {
        title: 'Admin',
        redirect: '/panel',
        icon: <RiAdminLine />,
    },
    {
        title: 'Forms',
        redirect: '/forms',
        icon: <FaWpforms />,
    },
    {
        title: 'Shifts',
        redirect: '/shifts',
        icon: <MdOutlineSchedule />,
    },
    {
        title: 'Data',
        redirect: '/data',
        icon: <BsDatabaseDown />,
    }
]

export const SidebarDataScouter = [
    {
        title: 'Home',
        redirect: '/',
        icon: <GoHomeFill />,
    },
    {
        title: 'Forms',
        redirect: '/forms',
        icon: <FaWpforms />,
    },
    {
        title: 'Shifts',
        redirect: '/shifts',
        icon: <MdOutlineSchedule />,
    },
    {
        title: 'Data',
        redirect: '/data',
        icon: <BsDatabaseDown />,
    }
]

export const SidebarDataBase = [
    {
        title: 'Home',
        redirect: '/',
        icon: <GoHomeFill />,
    },
    {
        title: 'Data',
        redirect: '/data',
        icon: <BsDatabaseDown />,
    }
]